<template>
  <div></div>
</template>

<script>
import {VueEasyJwt} from 'vue-easy-jwt'
import get_utms from '../utils/utm_params'

export default {
  name: 'Splash',

  data() {
    return {
      jwt: new VueEasyJwt(),
      utms: get_utms()
    }
  },
  methods: {
    async check_token() {
      const token = this.$store.state.auth.token
      if (token) {
        // Comprobamos que el token sea válido
        if (this.jwt.isExpired(this.$store.state.auth.token)) {
          await this.$router.push({name: "login", query: {next: 'centros'}, replace: true})
          return;
        }

        try {
          const user = await this.$store.dispatch('auth/me')
          if (user) {
            let reservas_pendientes = 0
            user.reservas_usuario_portal.forEach(
                current_reserva => {
                  if (current_reserva.estado.id === 2) {
                    ++reservas_pendientes
                  }
                }
            )
            if (reservas_pendientes > 0) {
              const resp = await this.$alert(
                  "Para poder realizar una nueva reserva, primero tienes que anular las citas pendientes.",
                  "",
                  'info'
              )
              await this.$router.replace({
                name: 'reservas',
                query: {
                  next: 'centros',
                  'utm_source': this.utms.utm_source,
                  'utm_medium': this.utms.utm_medium,
                  'utm_campaign': this.utms.utm_campaign
                }
              })
              return
            } else {
              await this.$router.replace({name: "centros", query: this.utms})
              return
            }
          }
        } catch (err) {
        }
      }
      await this.$router.replace({
        name: 'login', query: {
          next: 'centros',
          'utm_source': this.utms.utm_source,
          'utm_medium': this.utms.utm_medium,
          'utm_campaign': this.utms.utm_campaign
        }
      })
    },

    generateToken() {
      const token = 'xxxx-xxxx-yxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      }).toUpperCase();
      if (!this.$store.state.auth.token_stat) {
        this.$store.commit('auth/set_token_stat', token)
      }
    }
  },

  mounted() {
    this.$store.commit('common/set_show_toolbar', false)
    this.check_token()
    this.generateToken()
  }
}
</script>
